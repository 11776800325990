@import 'assets/css/constants.scss';

@mixin input--size($padding, $fontSize, $class) {
  &.input--#{$class} {

    .input {
      padding-top: $padding;
      padding-bottom: $padding;
      font-size: $fontSize;

      &:focus {
        padding-top: $padding - 1;
        padding-bottom: $padding - 1;
      }

      &.error {
        padding-top: $padding;
        padding-bottom: $padding;

        &:focus {
          padding-top: $padding - 1;
          padding-bottom: $padding - 1;
        }
      }
    }
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: #767676;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  @include input--size(15px, 16px, large);
  @include input--size(12px, 14px, medium);
  @include input--size(10px, 12px, small);

  .input {
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    background-color: #fff;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #212731;
    flex: 1;

    &:focus {
      border: solid 2px #1976D2;
      padding: 14px;
    }

    &.error {
      border: solid 1px $color-alert;
      padding: 15px;

      &:focus {
        border: solid 2px $color-alert;
        padding: 14px;
      }
    }
  }

  .clear-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    color: #212731;
    cursor: pointer;
    outline: none;

    &:hover {
      color: #000;
    }

    .icon {
      font-size: 12px;
    }
  }
}

.error-input-message {
  margin-top: 5px;
  color: $color-alert;
  font-size: 10px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 4px;
}
