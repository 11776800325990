@import 'assets/css/constants.scss';

.checkbox.input-wrapper {
  input {
    display: none;
  }

  input + label {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 25px;
    color: rgb(22, 22, 22);
    margin-bottom: 0;

    > div {
      display: flex;
      flex-direction: column;

      > span {
        &:first-of-type {
          font-size: 12px;
          color: #FFF;
          font-weight: 400;
        }
      }
    }

    &:hover {
      > div > span:last-of-type {
        text-decoration: underline;
      }
    }
  }

  input:disabled + label::after {
    background-color: #A3ADC2;
    cursor: not-allowed;
    border-radius: 6px;
  }

  input + label::before {
    content: " ";
    margin-right: 10px;
    width: 17px;
    height: 17px;
    background-color: #FFF;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 6px;
    flex-shrink: 0;
  }

  input:checked + label::after {
    content: " ";
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjRkZGIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center center;
    background-color: $color-main;
    border: 1px solid $color-main;
    position: absolute;
    border-radius: 6px;
    left: 0px;
    top: 2px;
    font-size: 10px;
    height: 18px;
    width: 18px;
  }

  input:indeterminate + label::after {
    content: " ";
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IS0tIFVwbG9hZGVkIHRvOiBTVkcgUmVwbywgd3d3LnN2Z3JlcG8uY29tLCBHZW5lcmF0b3I6IFNWRyBSZXBvIE1peGVyIFRvb2xzIC0tPgo8c3ZnIGZpbGw9IiNmZmZmZmYiIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCIgdmlld0JveD0iMCAwIDMyIDMyIiBpZD0iaWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNiIgeT0iMTMiIHdpZHRoPSIyMCIgaGVpZ2h0PSI2Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center center;
    background-color: $color-main;
    border: 1px solid $color-main;
    position: absolute;
    border-radius: 6px;
    left: 0px;
    top: 2px;
    font-size: 10px;
    height: 18px;
    width: 18px;
  }

  input:checked:disabled + label::after, input:indeterminate:disabled + label::after {
    background-color: #A3ADC2;
    border: 1px solid #A3ADC2;
  }
}