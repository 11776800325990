.layer-panel {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .radio input {
    margin-left: 3px;
    transform: scale(1.3);
  }

  .label {
    position: relative;
    width: 100%;

    .tooltip {
      opacity: 0;
      background-color: black;
      color: #fff;
      text-align: center;
      padding: 5px;
      font-size: 9px;
      font-weight: 800;
      border-radius: 6px;
      margin-left: 10px;
      top: -1px;
      position: absolute;
      z-index: 1;
      transition: all 0.1s ease;
    }

    &:hover .tooltip {
      opacity: 1;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .accordion .wrapper {
    display: flex;
    height: 0;
    flex-direction: column;
    gap: 12px;
    transition: none;
    padding-left: 16px;

    > * {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }

    h3 {
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }
  }
}