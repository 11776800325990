@font-face {
  font-family: 'Avenir';
  src: url(Avenir-Roman.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir';
  src: url(Avenir-Light.woff) format('woff');
  font-weight: 400;
}