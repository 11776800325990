@import 'assets/css/constants.scss';

.info-panel {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 60px 16px;
  gap: 16px;
  max-width: 500px;
  flex: 1;

  .objects-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .sources {
      gap: 6px;
      display: flex;
      flex-direction: column;

      .source {
        color: $color-main;
        font-weight: 800;
        margin-bottom: 4px;
      }
  
      .data {
        cursor: pointer;
        gap: 8px;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .object-single {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 ;

    .divider {
      width: 100%;
      height: 1px;
      background: #EEF3FB
    }

    .header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
    
      > p {
        font-family: Avenir;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        margin-right: auto;
      }

      > svg {
        cursor: pointer;
      }

      .menu {
        position: absolute;
        right: 0;
        top: 30px;
        background: white;
        box-shadow: 0px 1px 7.7px -2px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        z-index: 1;
        padding: 12px;

        button {
          height: 32px;
          min-height: unset;
          font-size: 16px;

          &.selected {
            color: $color-main;
          }
        }

        &.display {
          display: flex;
        }
      }

      .comments-count {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0px;
        top: -2px;
        background-color: rgb(234, 45, 45);
        color: white;
        border-radius: 50%;
        font-size: 12px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .divider {
        margin: 8px 0;
      }

      .collections-infos {
        .title {
          display: flex;
          gap: 4px;
        }

        .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .item-fa {
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            
            button {
              background: $color-main;
              color: $color-white;
              padding: 4px;
              border-radius: 4px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .comments {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      flex: 1;

      .list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: calc(100vh - 428px);
        overflow-x: hidden;

        .comment {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 8px;
          border-radius: 8px;
          background: #F5F9FF;

          .head {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 10px;

            .user.loading {
              background: #eee !important;
              color: transparent !important;
              border-radius: 4px !important;
              background-size: 200% 100% !important;
              animation: 1.5s shine linear infinite;
            }

            .date {
              font-size: 12px;
              font-style: italic;
              margin-left: auto;
            }

            .menu {
              position: absolute;
              right: 0;
              top: 30px;
              background: white;
              box-shadow: 0px 1px 7.7px -2px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
              display: none;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              z-index: 1;
              padding: 4px 6px;
      
              button {
                height: 26px;
                min-height: unset;
                font-size: 13px;
              }
      
              &.display {
                display: flex;
              }
            }
          }

          > p {
            font-size: 16px;
            font-weight: 800;
          }
        }
      }

      .add-comment {
        display: flex;
        flex-direction: column;
        gap: 8px;

        button {
          margin-left: auto;
        }
      }
    }
  }

  .maps-links {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 8px 12px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2705882353);
    z-index: 1;
    background-color: white;

    svg, img {
      height: 24px;
      width: 24px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
      }

      // target google maps
      &:last-of-type {
        gap: 2px;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@keyframes shine {
  0% {
    background: #acacac !important;
  }
  50% {
    opacity: 0.5;
    background: #fdfcfc !important;
  }
  100% {
    background: #acacac !important;
  }
}