/* * * * COLORS * * * */
$color-white: #FFFFFF;
$color-black: #161616;
$color-main: #256EFF;
$color-main-black: #212731;
$color-secondary: #A3ADC2;
$color-header-bar: #3A4457;
$color-alert: #DA4238;
$color-success: #43CF7B;
$color-info: #3498DB;
$color-warning: #FAB142 ;
$color-error: #DA4238 ;

/* * * * BACKGROUND * * * */
$background-success: #D9F5E4;
$background-info: #256EFF26 ;
$background-warning: #FDEFDA ;
$background-error: #FEE1DC ;
$background-purple-light: #D8D7F8 ;

/* * * * SIZES * * * */
$topbar-height: 72px;
$toolbar-height: 56px;