.modal-confirm {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 40px;

  .warning-icon {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background: #FDEFDA;
    border-radius: 8px;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 800;
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
  }
  
}