@import 'assets/css/constants.scss';

.contents-manager {
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(100vh - $topbar-height - $toolbar-height);
  height: calc(100svh - $topbar-height - $toolbar-height);
  background: rgb(243, 243, 243);

  div.content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex: 1;
    min-width: 250px;

    .content-loading-wrapper .loader {
      top: 10px;
      right: 10px;
      position: absolute;
      z-index: 1;
    }

    .content-toggler {
      position: absolute;
      top: 10px;
      left: 15px;
      z-index: 1;
      padding: 6px;
      border-radius: 12px;
      background: #F5F9FF;
      gap: 2px;
      height: 42px;

      svg {
        cursor: pointer;
        padding: 6px;
        width: 32px;
        height: 32px;

        &.active {
          border-radius: 9px;
          background: #FFF;
          box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.20);

          * {
            fill: black;
          }
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      svg * {
        fill: #A3ADC2;
      }
    }
  }

  &.truncate {
    > div:first-of-type {
      border-right: 1px solid #E0E0E0;
    }
  }
}