@import 'assets/css/constants.scss';

.tools-panel {
  position: absolute;
  height: calc(100vh - $topbar-height - $toolbar-height);
  height: calc(100svh - $topbar-height - $toolbar-height);
  left: 0;
  top: 56px;
  max-width: 320px;
  width: 100%;
  padding: 24px;
  background-color: white;
  transition: all 0.15s ease;
  overflow-y: auto;
  z-index: 2;
  box-shadow: -2px 5px 10px #00000045;

  .close-burger {
    position: absolute;
    top: 24px;
    right: 24px;
    transition: all 2s ease;
    cursor: pointer;
  }

  .tool-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;

    button.simple-button {
      min-height: 50px;
    }

    * {
      transition: all 0.15s ease;
    }
    
    .separator {
      height: 1px;
      background-color: #EEF3FB;
    }
  }

  &.hidden {
    overflow-y: hidden;
    transform: translateX(-280px);
    padding: 24px 8px;

    .close-burger {
      right: 8px;
    }

    .tool-wrapper {
      h3, &:not(.to-animate) {
        opacity: 0;
        pointer-events: none;
      }

      &.to-animate {
        button.simple-button {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          width: 100%;
  
          svg {
            margin-right: 0;
          }
        }
      }
    }
  }
}