@import 'assets/css/constants.scss';

.loader {
  position: relative;

  .spinner {
    border-radius: 50%;
    background: conic-gradient(#0000 10%, $color-main);
    animation: spinner-animation 1s infinite linear;
  }

  .progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: $color-main;
  }

  &.normal {
    .spinner {
      width: 56px;
      height: 56px;
      mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    }

    .progress {
      font-size: 12px;
    }
  }

  &.small {
    .spinner {
      width: 36px;
      height: 36px;
      mask: radial-gradient(farthest-side,#0000 calc(100% - 6px),#000 0);
    }

    .progress {
      font-size: 8px;
    }
  }

  &.large {
    .spinner {
      width: 76px;
      height: 76px;
      mask: radial-gradient(farthest-side,#0000 calc(100% - 12px),#000 0);
    }

    .progress {
      font-size: 14px;
    }
  }
  
  &.x-small {
    .spinner {
      width: 24px;
      height: 24px;
      mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
    }

    .progress {
      font-size: 6px;
    }
  }

  &.standalone {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  @keyframes spinner-animation {
      to {
        transform: rotate(1turn);
      }
  }
}