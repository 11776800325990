.tools-bar {
  display: flex;
  align-items: center;
  gap: 32px;
  height: 56px;
  padding: 0 24px;
  width: 100%;
  background-color: #3A4457;

  button.simple-button.layers {
    margin-left: auto;
  }

  @media screen and (max-width: 1215px) {
    gap: 8px;
  }
}