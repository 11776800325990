
#handle {
  background: #7a7a7a;
  cursor: col-resize;
  gap: 1px;
  height: 100%;

  > div {
    pointer-events: none;
    width: 2px;
    background: #424242;
  }
}