@import 'assets/css/constants.scss';

.maps-selection-wrapper {
  display: flex;
  gap: 16px;
  padding-bottom: 29px;

  button.map-button {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    align-items: center;

    > div, > img {
      width: 110px;
      height: 48px;
      overflow: hidden;
      border-radius: 4px;
      border: 2px solid transparent;
    }

    img {
      object-fit: cover;
      object-position: center;
    }

    &.truncate div {
      position: relative;
      display: flex;

      img {
        width: 50%;

        &:last-of-type {
          border: 1px dashed black;
        }
      }

      .shadow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        background-color: black;
        opacity: 0.2;
      }

      svg {
        position: absolute;
        top: 11px;
        right: 15px;
        z-index: 1;
        
        * {
          fill: black;
        }
      }
    }

    &:hover {
      color: $color-main;

      > div, > img {
        border-color: $color-main;
      }
    }
  }  
}