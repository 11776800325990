@import 'assets/css/constants.scss';

@mixin button-modifier($color, $fontColor, $borderColor, $class) {
  &.button--#{$class} {
    background: $color;
    color: $fontColor;
    border: 1px solid $borderColor;

    svg * {
      fill: $fontColor;
    }

    .loader .spinner {
      background: conic-gradient(rgba(0, 0, 0, 0) 10%, $fontColor);
    }

    &:hover {
      background: darken($color, 5%);
    }
  }
};

@mixin button-size($pading, $fontSize, $min-height, $class) {
  &.button--#{$class} {
    padding: $pading;
    font-size: $fontSize;
    min-height: $min-height;
  }
};

button.simple-button {
  padding: 3px 16px;
  display: flex;
  height: 21px;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: unset;
  text-transform: unset;
  border-radius: 12px;

  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:hover {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  @include button-size(3px 16px, 14px, 36px, small);
  @include button-size(6px 16px, 16px, 56px, medium);
  @include button-size(8px 16px, 18px, 66px, large);
  @include button-size(18px 30px, 18px, 72px, xlarge);
  @include button-size(2px 8px, 12px, 21px, xsmall);

  @include button-modifier($color-main, $color-white, transparent, primary);
  @include button-modifier($color-white, $color-black, transparent, secondary);
  @include button-modifier(transparent, $color-main, $color-main, light);
  @include button-modifier(transparent, $color-black, transparent, borderLess);
  @include button-modifier(transparent, $color-white, transparent, borderLessWhite);
  @include button-modifier(transparent, $color-main, transparent, borderLessPrimary);
  @include button-modifier(#DA4238, $color-white, #DA4238, delete);

  &.is-borderLess {
    padding: 0;
  }

  &.underline-text:hover {
    text-decoration: underline;
  }

  &.inline {
    position: relative;
    display: inline-flex;
    height: unset;
    min-height: unset;

    .loader {
      transform: scale(.4);
      position: absolute;
      right: -30px;
      top: -11px;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    
    &:hover {
      text-decoration: none;
    }
  }
}