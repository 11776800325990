@import 'assets/css/constants.scss';

.perimeter-fields {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .description-input {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: #767676;
      font-weight: 400;
      margin-bottom: 5px;
    }

    textarea {
      resize: none;
      border-radius: 6px;
      border: 1px solid #d5d5d5;
      background-color: #fff;
      padding: 15px;
      font-size: 16px;
      font-weight: 400;
      color: #212731;
      flex: 1;

      &:focus {
        border: solid 2px #1976D2;
        padding: 14px;
      }

      &.error {
        border: solid 1px $color-alert;
        padding: 15px;

        &:focus {
          border: solid 2px $color-alert;
          padding: 14px;
        }
      }
    }
  }
}