.on-screen-panel {
  padding: 16px;
  min-width: 500px;
  max-width: 500px;

  .tabs {
    padding: 6px;
    border-radius: 12px;
    background: #F5F9FF;
    margin-bottom: 16px;

    button {
      height: unset;
      padding: 16px;
      flex: 1;

      &.selected {
        border-radius: 9px;
        background: #FFF;
        box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.20);
      }
    }
  }

  .accordion {
    max-width: 452px;
    margin-bottom: 12px;

    h3 {
      color: #256EFF;
      font-weight: 800;
      font-size: 18px;
      margin-bottom: 0 !important;
    }

    svg {
      fill: #256EFF;
    }
  }
}