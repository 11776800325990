@import 'assets/css/constants.scss';

#access-denied {
  height: calc(100vh - $topbar-height);
  height: calc(100svh - $topbar-height);
  font-family: Avenir;
  text-align: center;
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .warning-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;

    .warning-icon {
      height: 100px;
      width: auto;
    }

    .main-message {
      max-width: 600px;
      font-size: 36px;
      font-weight: 900;
    }
  }
}