.search-panel {
  padding: 16px;
  width: 450px;

  .tabs {
    padding: 6px;
    border-radius: 12px;
    background: #F5F9FF;
    margin-bottom: 16px;

    button {
      height: unset;
      padding: 16px;
      flex: 1;

      &.selected {
        border-radius: 9px;
        background: #FFF;
        box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.20);
      }
    }
  }

  .input-wrapper.text {
    margin-bottom: 16px;
  }

  .search-object {
    display: flex;
    flex-direction: column;

    span {
      color: #767676;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
}

.search-panel-header.panel-title {
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 12px;
    color: #767676;
  }
}