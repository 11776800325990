.accordion {
  width: 100%;
  position: relative;

  h3 {
    font-weight: 400;
    height: 28px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 16px;
  }

  > svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 3px;
    right: 0;
    transition: all 0.15s ease-in-out;
    pointer-events: none;
  }

  .wrapper {
    display: flex;
    height: 0;
    flex-direction: column;
    gap: 12px;
    transition: none;
  }

  &.enable-transition .wrapper {
    transition: all 0.2s ease-in-out;
  }

  &.expanded {
    > svg {
      transform: rotate(180deg);
    }
  }
}