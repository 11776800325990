.tooltip-label {
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 9px;
  font-weight: 800;
  border-radius: 6px;
  position: fixed;
  z-index: 100;
  width: max-content;
}
