@import 'assets/css/constants.scss';

.favorites-panel {
  .tabs {
    width: fit-content;
    margin: 12px auto;
    padding: 6px;
    border-radius: 12px;
    background: #F5F9FF;

    button {
      height: unset;
      padding: 16px;

      &.selected {
        border-radius: 9px;
        background: #FFF;
        box-shadow: 0px 4px 20px -3px rgba(0, 0, 0, 0.20);
      }
    }
  }
  
  .divider {
    width: 100%;
    height: 1px;
    background: #EEF3FB
  }

  .favorites {
    height: calc(100vh - 304px);
    transition: all 0.2s ease;
  
    .favorite {
      padding: 16px;
      border-bottom: 1px solid #EEF3FB;
      cursor: pointer;

      &.objects {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .head {
          justify-content: space-between;
          gap: 8px;

          p {
            word-break: break-word;
            max-width: 300px;
            margin-right: auto;

            span.info {
              color: $color-main;
              font-size: 12px;
              font-weight: 400;
            }
          }

          svg {
            cursor: pointer;

            * {
              fill: $color-main
            }
          }
        }

        p.lvp {
          word-break: break-word;
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            white-space: unset;
          }
        }
      }

      &:hover {
        background: #F5F9FF;
      }
    }

    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}