@import 'assets/css/constants.scss';

.panels-manager {
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - $topbar-height - $toolbar-height);
  height: calc(100svh - $topbar-height - $toolbar-height);
  max-width: fit-content;
  flex: 0;
  background: white;
  overflow: hidden;
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 2px 5px 10px #00000045;

  &:has(.collection-panel) {
    overflow: hidden;
  }

  .panel-title {
    min-width: 320px;
    max-width: 500px;
    padding: 16px 62px 16px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eef3fb;

    h3 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    button {
      height: 24px;
      min-height: unset;
    }
  }

  .close {
    position: absolute;
    top: 16px;
    right: -16px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  &.displayed {
    flex: 2;

    .close {
      right: 16px;
    }
  }
}
