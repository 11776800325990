.editor-layer {


  .editor-layer-actions {
    position: absolute;
    top: 10px;
    right: 60px;
    display: flex;
    gap: 10px;
  }
}