@import 'assets/css/constants.scss';

.perimeter-panel-title.panel-title {
  max-width: 600px;
}

.perimeters-panel {
  max-width: 600px;

  .text.input-wrapper {
    margin: 15px 24px 24px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;

    th {
      background: #212731;
      color: white;
      font-weight: 600;
      text-align: left;
      padding: 21px 16px;

      &:has(div.description) {
        width: 200px;
      }

      &:has(div.name) {
        width: 200px;
      }

      &:has(div.date) {
        width: 96px;
      }

      &:has(div.access) {
        width: 86px;
      }

      &:has(div.actions) {
        width: 74px;
      }

      &:not(:last-of-type) {
        border-right: 1px solid #E2E7EF;
      }
    }

    td {
      padding: 8px 16px;

      &:has(div.description) {
        width: 200px;
        padding: 8px 50px 8px 16px;
      }

      &:has(div.name) {
        width: 200px;
      }

      div.name {
        display: flex;
        flex-direction: column;

        > span {
          color: $color-main;
          font-weight: 500;
          font-size: 11px;
          text-transform: lowercase;
        }
      }

      div.description {
        position: relative;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
                  line-clamp: 2; 
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          max-height: 36px;
          font-size: 11px;

          &.expanded {
            max-height: unset;
            overflow: visible;
            -webkit-line-clamp: unset;
                    line-clamp: unset; 
          }
        }

        button {
          text-transform: lowercase;
          position: absolute;
          height: 20px;
          min-height: unset;
          font-size: 11px;
          right: -45px;
          bottom: -3px;
          color: $color-main;
        }

        &.break-word span {
          word-break: break-word;
        }
      }

      div.access {
        button {
          width: 54px;
          height: 32px;
  
          svg {
            margin-right: 0;
          }
        }
      }

      div.actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
  
        button {
          height: 32px;
  
          svg {
            margin-right: 0;
          }
        }
      }
    }

    tr:nth-child(odd) {
      background: #F5F9FF;
    }

    tr:hover {
      cursor: pointer;
      background: #EEF3FB;
    }
  }
}

@media screen and (max-width: 915px) {
  .views-panel table {
    font-size: 12px;

    td {
      padding: 4px 8px;
    }
  }
}