@import 'assets/css/constants.scss';

.text.input-wrapper {
  &.align-left {
    input {
      text-align: left;
    }
  }

  &.align-center {
    input {
      text-align: center;
    }
  }

  &.align-right {
    input {
      text-align: right;
    }
  }

  &.disabled {
    input {
      background-color: #D7E1F3;
      color: #A3ADC2;
    }
  }

  &.has-left-icon {
    input {
      padding-left: 40px;
    }
  }

  &.has-right-icon {
    input {
      padding-right: 40px;
    }
  }

  .icon {
    top: 50%;
    transform: translateY(-50%);
    max-width: 24px;
    max-height: 24px;
    width: 100%;

    * {
      fill: $color-black;
    }

    position: absolute;

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }
}