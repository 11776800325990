.report-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
  }
}