@import 'assets/css/constants.scss';

.save-perimeter-modal {
  display: flex;
  flex-direction: column;
  gap: 48px;

  p.info {
    gap: 12px;
    text-align: center;
    min-width: 593px;
  }

  h3 {
    color: $color-main;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  }
}