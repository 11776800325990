table.result-table {
  border-collapse: collapse;
  font-size: 13px;

  th {
    text-align: left;
    font-weight: 600;
  }

  th, td {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  tbody tr {
    &:hover {
      background: #F5F9FF;
      cursor: pointer;
    }
  }
}