@import 'assets/css/constants.scss';

.save-view-modal {
  display: flex;
  flex-direction: column;
  gap: 48px;

  p.info {
    gap: 12px;
    text-align: center;
    min-width: 593px;
  }

  h3 {
    color: $color-main;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  }
}

.view-saved-modal {
  display: flex;
  flex-direction: column;
  gap: 48px;

  p.info {
    gap: 12px;
    text-align: center;
    min-width: 593px;

    svg {
      background-color: #43CF7B;
      width: 56px;
      height: 56px;
      padding: 10px;
      border-radius: 50%;
    }
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  }
}