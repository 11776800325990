.collections {
  width: 100%;
  padding: 72px 0 0 0;
  align-self: flex-start;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  .input-wrapper.text {
    margin: 0 24px 24px 24px;
  }

  .table-wrapper {
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 13px;
  
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }
  
      th {
        background: #212731;
        color: white;
        font-weight: 600;
        text-align: left;
        padding: 21px 16px;
        border-right: 1px solid #E2E7EF;
  
        &:has(div.actions) {
          max-width: 150px;
        }
      }
  
      td {
        padding: 8px 16px;

        div.label {
          max-width: 150px;
        }
  
        div.ferroloc, div.type, div.techno {
          display: flex;
          flex-wrap: wrap;
          gap: 2px;
  
          span {
            padding: 2px 4px;
            background-color: #374459;
            color: white;
            border-radius: 4px;
          }
        }
  
        &:has(div.actions) {
          min-width: 150px;
          
          button {
            flex-shrink: 0;
            padding: 4px 8px;
            transform: scale(0.8);
          }
        }
      }
  
      tr:nth-child(odd) {
        background: #F5F9FF;
      }
  
      tr.skeleton {
        height: 54px;
        td {
          div {
            opacity: 0.8;
            height: 24px;
            animation: skeleton 1s linear infinite alternate;
            border-radius: 3px;
          }
  
          &:nth-child(1) div {
            width: 100%;
            max-width: 150px;
          }

          &:nth-child(2) div {
            width: 100%;
            max-width: 100px;
          }
  
          &:nth-child(3) div {
            width: 100%;
            max-width: 150px;
          }
  
          &:nth-child(4) div {
            width: 100%;
            max-width: 50px;
          }
  
          &:nth-child(5) div {
            width: 95px;
            margin-left: 12px;
          }
        }
      }

      tr:not(.skeleton):hover {
        cursor: pointer;
        background: #EEF3FB;
      }
    }

    h3 {
      text-align: center;
      margin-top: 20vh;
    }
  }



  @keyframes skeleton {
    0% {
      background-color: hsl(203, 25%, 83%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}