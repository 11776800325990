@import 'assets/css/constants.scss';

header {
  background-color: #2F2F2F;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  * {
    color: white;
    font-weight: 800;
  }

  .logos {
    display: flex;
    gap: 28px;

    a {
      gap: 18px;

      img {
        height: 25px;
        width: auto;

        &.dexlogo {
          height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  header .logos > img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    .logos {
      display: none;
    }
  }
}
