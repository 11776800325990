.account-menu {
  margin-left: auto;
  position: relative;

  > button {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.029em;

    svg {
      height: 20px;
      
      * {
        fill: white;
      }
    }
  }

  > ul {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 5px -3px, rgba(0, 0, 0, 0.1) 0px 8px 10px 1px, rgba(0, 0, 0, 0.08) 0px 3px 14px 2px;
    border-radius: 4px;
    flex-direction: column;
    opacity: 0;
    transition: all 0.2s ease;
    pointer-events: none;
    overflow: hidden;
    gap: 8px;
    z-index: 999;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 16px;
      cursor: pointer;

      &:hover {
        background-color: #F5F5F5;
      }

      a {
        color: black;
        text-decoration: none;
        font-weight: 400;
        gap: 10px;

        svg {
          height: 20px;
        }
      }
    }

    &.displayed {
      top: 30px;
      opacity: 1;
      pointer-events: all;
    }
  }
}