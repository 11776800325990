.map-controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 40px;
  right: 24px;
  gap: 12px;

  .scale-control {
    border-radius: 8px;
    background: white;
    width: 92px;
    height: 40px;
    box-shadow: 0px 1px 7.7px -2px #00000026;

    .mapboxgl-ctrl-scale {
      text-align: center;
      font-weight: 800;
      padding: 0;
      padding-bottom: 2px;
    }
  }

  .map-control-buttons {
    display: flex;
    gap: 12px;
    flex-direction: row;

    .map-control-button {
      background: white;
      border-radius: 8px;
      box-shadow: 0px 1px 7.7px -2px #00000026;

      &.geo-sync button {
        padding-left: 5px;
        padding-top: 2px;
      }
  
      button {
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: inherit;
  
        &:hover {
          background-color: rgba(0, 0, 0, .05);;
        }
      }
    }
  
    .navigation-control .mapboxgl-ctrl-group  {
      box-shadow: 0px 1px 7.7px -2px #00000026;
      border-radius: 8px;
  
      button.mapboxgl-ctrl-compass {
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: inherit;
  
        .mapboxgl-ctrl-icon {
          padding: 4px;
          background-image: url('/assets/icons/navigation-pointer.svg') ;
        }
      }
    }
  }

}