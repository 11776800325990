@import 'assets/css/constants.scss';

.infos-tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $color-main-black;

  > p {
    word-wrap: break-word;
  }

  h4.color-blue {
    color: $color-main;
  }

  .lines {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .line-accordion {

      h3 {
        font-weight: 800;
      }

      .track-details {
        font-size: 14px;
        display: flex;
        gap: 8px;
  
        span:last-child {
          color: $color-secondary;
        }
      }
    }
  }
}