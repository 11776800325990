.search-results {
  display: flex;
  flex-direction: column;

  &.empty {
    width: 400px;
    color: #A3ADC2;
  }

  .accordion {
    .wrapper {
      gap: 0;
    }

    h3 {
      color: #256EFF;
      font-weight: 800;
      font-size: 18px;
    }

    svg {
      fill: #256EFF;
    }
  }

  button.result {
    padding: 4px 0;
    text-align: left;
    font-size: 16px;
    max-width: 400px;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover, &:focus {
      background: #F5F9FF;
    }
  }
}